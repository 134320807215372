/* eslint-disable array-callback-return */
import { all, takeEvery, call, put, take } from 'redux-saga/effects';
import { historyG as history } from 'dcom-app/dist/containers/main/MainApp';
import { message as MessageManager } from 'antd';
import { push } from 'puppeteer/DeviceDescriptors';
import { getGroups, groupByIncomeExpense } from '../../helpers/groupBy';
import servicesApi from './services';
import actions from './actions';
import createDate from '../../helpers/createDate';

function* startAppSaga({ payload }) {
  const { userid } = payload;
  if (!userid) {
    // IF USER_ID IS NOT FOUND REDIRECT TO HOMEPAGE
    return yield put(push('/'));
  }
  try {
    // CHECK IF USER HAS CREATED WORKSHEET PREVIOUSLY
    yield put(actions.getWorkSheetByUser({ userid }));
    const worksheetByUser = yield take('GET_WORKSHEET_SUCCESS_BY_USER');
    if (worksheetByUser.payload.length === 0) {
      const todayDate = yield createDate();
      yield put(actions.createWorkSheet({ date: todayDate, userId: userid }));
    }
    return yield put(actions.fetchAttrs());
  } catch (error) {
    console.log('An error happened in start app saga');
    return yield put(push('/'));
  }
}

function* createWorkSheetSaga({ payload }) {
  try {
    const workSheet = yield call(servicesApi.createWorkSheet, payload);
    if (!workSheet.isAxiosError) {
      yield put(actions.createWorkSheetSuccess(workSheet));
      return;
    }
    // error
    const { message } = workSheet;
    console.log('Error: incomeAttrs:-->', message);
    yield put(actions.createWorkSheetError({ error: message }));

    if (workSheet.response && workSheet.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    yield put(actions.createWorkSheetError(error));
  }
}

function* getWorkSheetByUserSaga({ payload }) {
  try {
    const workSheets = yield call(servicesApi.getWorkSheetByUser, payload);
    if (!workSheets.isAxiosError) {
      yield put(actions.getWorkSheetSuccessByUser(workSheets));
      return;
    }
    // error
    const { message } = workSheets;
    console.log('Error: incomeAttrs:-->', message);
    yield put(actions.getWorgetWorkSheetErrorByUserkSheetError({ error: message }));

    if (workSheets.response && workSheets.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    // console.log(error);
    yield put(actions.getWorkSheetErrorByUser(error));
  }
}

export function* fetchAttrsSaga() {
  const INCOME = 'Income';
  const EXPENSE = 'Expense';
  const GOAL = 'Goal';
  const ABOUTYOU = 'AboutYou';

  const domain = `${window.location.hostname.replace(/\/$/, '')}`;
  try {
    const attrs = yield call(servicesApi.fetchAttrs, domain);
    if (!attrs.isAxiosError) {
      const cleanAttrs = attrs.filter(attr => attr.attribute).reverse();
      // get all groups from the raw data
      const allCategories = yield call(getGroups, cleanAttrs);
      const incomeCategories = getGroups(
        cleanAttrs.filter(attr => attr.attribute.incomeExpense === INCOME),
      );
      const expenseCategories = getGroups(
        cleanAttrs.filter(attr => attr.attribute.incomeExpense === EXPENSE),
      );
      const goalCategories = getGroups(
        cleanAttrs.filter(attr => attr.attribute.incomeExpense === GOAL),
      );
      const aboutYouCategories = getGroups(
        cleanAttrs.filter(attr => attr.attribute.incomeExpense === ABOUTYOU),
      );
      // Income attrs
      const incomeAttrs = groupByIncomeExpense(cleanAttrs)[INCOME] || [];
      // Goals attrs
      const goalAttrs = groupByIncomeExpense(cleanAttrs)[GOAL] || [];
      // AboutYou attrs
      const aboutYouAttrs = groupByIncomeExpense(cleanAttrs)[ABOUTYOU] || [];
      // Expense attrs
      const allExpenseAttrs = groupByIncomeExpense(cleanAttrs)[EXPENSE] || [];
      const expenseAttrs = {};
      expenseCategories.map(category => {
        expenseAttrs[category] = allExpenseAttrs.filter(
          expenseAttr => expenseAttr.attribute.type === category,
        );
      });
      // create routes
      const dynamicRoutes = [];
      cleanAttrs.map(({ attribute }) => {
        const exist = dynamicRoutes.find(route => route.id === attribute.categoryId.id);
        if (!exist) {
          return dynamicRoutes.push({
            id: attribute.categoryId.id,
            category: attribute.categoryId.name,
            path: attribute.categoryId.slug,
          });
        }
      });
      //  group Attrs by Categories
      const attrsGroupByCateg = {};
      const allAttributes = cleanAttrs.map(({ attribute }) => attribute.categoryId.name);
      allAttributes.map(categ => {
        attrsGroupByCateg[categ] = cleanAttrs.filter(attr => attr.attribute.type === categ);
        return false;
      });
      // dispatch success action
      yield put(
        actions.fetchAttrsSuccess({
          allCategories,
          incomeAttrs,
          incomeCategories,
          goalAttrs,
          goalCategories,
          aboutYouAttrs,
          aboutYouCategories,
          expenseAttrs,
          expenseCategories,
          dynamicRoutes,
          attrsGroupByCateg,
        }),
      );
      return;
    }
    // error
    const { message } = attrs;
    console.log('Error: paychecks:-->', message);
    yield put(actions.fetchAttrsError({ error: message }));

    if (attrs.response && attrs.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.fetchAttrsError(error));
  }
}

function* postAttrValueSaga({ payload }) {
  const { arrayAttrValues } = payload;
  try {
    const resp =
      arrayAttrValues.length !== 0 ? yield call(servicesApi.postAttrValue, arrayAttrValues) : {};
    if (!resp.isAxiosError) {
      yield put(actions.postAttrValueSuccess());
      // push to next view
      if (payload.nextView) history.push(payload.nextView);
      return;
    }
    // error
    const { message } = resp;
    console.log('Error: incomeAttrs:-->', message);
    yield put(actions.postAttrValueError({ error: message }));

    if (resp.response && resp.response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    // console.log(error);
    yield put(actions.postAttrValueError(error));
  }
}

function* getCompanyInfo() {
  const { hostname } = window.location;
  try {
    const response = yield call(servicesApi.getCompanyInfo, hostname);

    if (!response.isAxiosError) {
      yield put(actions.getCompanyInfoSuccess({ companyInfo: (response && response[0]) || {} }));
      return;
    }

    if (response && response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log('fetch error', error);
    const { message = '' } = error;
    yield put(actions.getCompanyInfoError({ error: message }));
  }
}

function* getBudgetSpendingCategories({ payload = {} }) {
  const { companyInfoId = 0 } = payload;

  try {
    const response = yield call(servicesApi.getBudgetSpendingCategories, { companyInfoId });

    if (!response.isAxiosError) {
      const { configurationSpending, categories } = response;
      yield put(actions.getBudgetSpendingCategoriesSuccess({ configurationSpending, categories }));
      return;
    }

    const { message = '' } = response;
    console.log('Error: getBudgetSpendingCategories:-->', message);
    yield put(actions.getBudgetSpendingCategoriesError({ error: message }));

    if (response && response.status === 401) {
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log('fetch error', error);
    const { message = '' } = error;
    yield put(actions.getBudgetSpendingCategoriesError({ error: message }));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.START_APP, startAppSaga)]);
  yield all([takeEvery(actions.CREATE_WORKSHEET, createWorkSheetSaga)]);
  yield all([takeEvery(actions.GET_WORKSHEET_BY_USER, getWorkSheetByUserSaga)]);
  yield all([takeEvery(actions.FETCH_ATTRS, fetchAttrsSaga)]);
  yield all([takeEvery(actions.POST_ATTR_VALUE, postAttrValueSaga)]);
  yield all([takeEvery(actions.GET_BUDGET_SPENDING_CATEGORIES, getBudgetSpendingCategories)]);
  yield all([takeEvery(actions.GET_COMPANY_INFO, getCompanyInfo)]);
}
