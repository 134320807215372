"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.checkTokenExpiration = void 0;
exports.clearToken = clearToken;
exports.decodeJwt = decodeJwt;
exports.getMoment = getMoment;
exports.getMomentFromSeconds = getMomentFromSeconds;
exports.getSeconds = getSeconds;
exports.getToken = getToken;
exports.reverseString = void 0;
exports.stringToInt = stringToInt;
exports.stringToPosetiveInt = stringToPosetiveInt;
exports.timeDifference = timeDifference;
var _jwtDecode = _interopRequireDefault(require("jwt-decode"));
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
function clearToken() {
  localStorage.removeItem('id_token');
}
function getToken() {
  try {
    var idToken = localStorage.getItem('id_token');
    // return new Map({ idToken });
    return idToken;
  } catch (err) {
    clearToken();
    return '';
  }
}
function decodeJwt(token) {
  return (0, _jwtDecode["default"])(token);
}
var checkTokenExpiration = function checkTokenExpiration(token) {
  if (!token) {
    return {
      error: 'token is null'
    };
  }
  try {
    var profile = (0, _jwtDecode["default"])(token);
    var user = profile.user,
      iat = profile.iat;
    if (iat > new Date().getTime()) {
      return _objectSpread(_objectSpread({}, profile), {}, {
        user: user,
        token: token,
        expiredAt: new Date(iat)
      });
    }
    return {
      error: 'Session expired please login again'
    };
  } catch (e) {
    return {
      error: 'bad token error'
    };
  }
};
exports.checkTokenExpiration = checkTokenExpiration;
function timeDifference(time) {
  var givenTime = new Date(time);
  var milliseconds = new Date().getTime() - givenTime.getTime();
  var numberEnding = function numberEnding(number) {
    return number > 1 ? 's' : '';
  };
  var number = function number(num) {
    return num > 9 ? "".concat(num) : "0".concat(num);
  };
  var getDiffTime = function getDiffTime() {
    var temp = Math.floor(milliseconds / 1000);
    var years = Math.floor(temp / 31536000);
    if (years) {
      var month = number(givenTime.getUTCMonth() + 1);
      var day = number(givenTime.getUTCDate());
      var year = givenTime.getUTCFullYear() % 100;
      return "".concat(day, "-").concat(month, "-").concat(year);
    }
    var days = Math.floor((temp %= 31536000) / 86400);
    if (days) {
      if (days < 28) {
        return "".concat(days, " day").concat(numberEnding(days));
      }
      var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      var _month = months[givenTime.getUTCMonth()];
      var _day = number(givenTime.getUTCDate());
      return "".concat(_day, " ").concat(_month);
    }
    var hours = Math.floor((temp %= 86400) / 3600);
    if (hours) {
      return "".concat(hours, " hour").concat(numberEnding(hours), " ago");
    }
    var minutes = Math.floor((temp %= 3600) / 60);
    if (minutes) {
      return "".concat(minutes, " minute").concat(numberEnding(minutes), " ago");
    }
    return 'a few seconds ago';
  };
  var getSMSDate = function getSMSDate(time) {
    // const DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    // const MONTH_NAMES = [
    //   'Jan',
    //   'Feb',
    //   'Mar',
    //   'Apr',
    //   'May',
    //   'Jun',
    //   'Jul',
    //   'Aug',
    //   'Sep',
    //   'Oct',
    //   'Nov',
    //   'Dec',
    // ];
    var dateIntance = new Date(time);
    // const day = DAY_NAMES[dateIntance.getDay()];
    var addZero = function addZero(elem) {
      return elem < 10 ? "0".concat(elem) : elem;
    };
    var month = addZero(dateIntance.getMonth() + 1);
    var date = addZero(dateIntance.getDate());
    var year = dateIntance.getFullYear();
    var hours = dateIntance.getHours() > 12 ? dateIntance.getHours() - 12 : dateIntance.getHours();
    var minutes = dateIntance.getMinutes();
    var am_pm = dateIntance.getHours() < 12 || dateIntance.getHours() === 0 ? 'am' : 'pm';
    return "[".concat(month, "/").concat(date, "/").concat(year, " ").concat(addZero(hours), ":").concat(addZero(minutes)).concat(am_pm, "]");
  };
  return {
    diffTime: getDiffTime(),
    smsDate: getSMSDate(givenTime.getTime())
  };
}
function stringToInt(value) {
  var defValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  if (!value) {
    return 0;
    // eslint-disable-next-line no-restricted-globals
  }

  if (!isNaN(value)) {
    return parseInt(value, 10);
  }
  return defValue;
}
function stringToPosetiveInt(value) {
  var defValue = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 0;
  var val = stringToInt(value, defValue);
  return val > -1 ? val : defValue;
}
function getSeconds(currentTime) {
  return currentTime.diff((0, _moment["default"])().startOf('day'), 'seconds');
}
function getMomentFromSeconds(seconds) {
  if (_typeof(seconds) === 'object') return seconds;
  return (0, _moment["default"])('00:00', 'hh:mm a').add(seconds || 0, 'seconds');
}
function getMoment(startDate) {
  if (typeof startDate === 'number') return getMomentFromSeconds(startDate);
  return startDate || (0, _moment["default"])('00:00', 'hh:mm a');
}
var reverseString = function reverseString(str) {
  return str.split('').reverse().join('');
};
exports.reverseString = reverseString;