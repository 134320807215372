"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _layoutContent = _interopRequireDefault(require("./layoutContent.style"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var _default = _layoutContent["default"];
exports["default"] = _default;