"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledTheme = require("styled-theme");
var _templateObject;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}
var StyledTable = function StyledTable(ComponentName) {
  return (0, _styledComponents["default"])(ComponentName)(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .ant-table-thead > tr > th {\n    color: ", " !important;\n    font-size: 14px !important;\n    font-weight: 500 !important;\n    background-color: ", "!important;\n    border-bottom: 0px !important;\n  }\n  .ant-table-tbody > tr > td {\n    font-size: 12px;\n    color: ", ";\n    p {\n      text-shadow: rgba(0, 0, 0, 0.004) 1px 1px 1px;\n    }\n  }\n  .ant-table-column-sorter {\n    margin-top: 0;\n    margin-bottom: 0;\n  }\n"])), (0, _styledTheme.palette)('secondary', 2), (0, _styledTheme.palette)('secondary', 1), (0, _styledTheme.palette)('text', 3));
};
var _default = StyledTable;
exports["default"] = _default;