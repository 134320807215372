"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledTheme = require("styled-theme");
var _templateObject;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}
var DashAppHolder = _styledComponents["default"].div(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  font-family: ", ";\n  h1,\n  h2,\n  h3,\n  h4,\n  h5,\n  h6,\n  a,\n  p,\n  li,\n  input,\n  textarea,\n  span,\n  div,\n  img,\n  svg {\n    &::selection {\n      background: ", ";\n      color: #fff;\n    }\n  }\n\n  .ant-row {\n    ", ";\n  }\n\n  .ant-row > div {\n    padding: 0;\n  }\n\n  .isoLeftRightComponent {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    width: 100%;\n  }\n\n  .isoCenterComponent {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    width: 100%;\n  }\n"])), (0, _styledTheme.font)("primary", 0), (0, _styledTheme.palette)("primary", 0), "" /* margin-left: -8px;
                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                         margin-right: -8px; */);
var _default = DashAppHolder;
exports["default"] = _default;