"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _themes = _interopRequireDefault(require("../themes"));
var _KOFE_logo = _interopRequireDefault(require("./assets/KOFE_logo.png"));
var _debtLogo = _interopRequireDefault(require("./assets/debt-logo.png"));
var _ccLogo = _interopRequireDefault(require("./assets/ccLogo.png"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var customizations = [
// Budget Application configurations
{
  id: 'configMobileApp',
  favicon: '',
  appTitle: '',
  appDescription: '',
  appLogo: '',
  logoLink: '',
  showProfile: false,
  theme: _themes["default"].theme2,
  appUrl: 'budget-1-app.debt.com'
}, {
  id: 'configKofeApp',
  favicon: 'favicons/kofe-favicon.ico',
  appTitle: 'Budget App by Kofe',
  appDescription: '',
  appLogo: _KOFE_logo["default"],
  logoLink: 'https://kofetime.com/',
  showProfile: true,
  theme: _themes["default"].theme3,
  appUrl: 'budget-2-app.debt.com'
}, {
  id: 'configDcom',
  favicon: '',
  appTitle: 'Budget App by Debt.com',
  appDescription: '',
  appLogo: _debtLogo["default"],
  logoLink: 'https://www.debt.com',
  showProfile: false,
  theme: _themes["default"].theme1,
  appUrl: 'localhost'
}, {
  id: 'configConsolidated',
  favicon: 'favicons/cc-favicon.ico',
  appTitle: 'Budget App by Consolidated Credit',
  appDescription: '',
  appLogo: _ccLogo["default"],
  logoLink: 'https://www.consolidatedcredit.org',
  showProfile: false,
  theme: _themes["default"].theme2,
  appUrl: 'budget-app.debt.com'
},
// Dynamic Form Configs
{
  id: 'dynFormDebt',
  favicon: '',
  appTitle: 'Dynamic Form by Debt.com',
  appDescription: '',
  appLogo: _debtLogo["default"],
  logoLink: 'https://www.debt.com',
  showProfile: false,
  theme: _themes["default"].theme4,
  appUrl: 'dev-dynamic-form-frontend.debt.com' //'dev-dynamic-form-frontend.debt.com',
}];

var _default = customizations;
exports["default"] = _default;