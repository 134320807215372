"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = createAppStore;
var _redux = require("redux");
var _history = require("history");
var _connectedReactRouter = require("connected-react-router");
var _reduxThunk = _interopRequireDefault(require("redux-thunk"));
var _reduxSaga = _interopRequireDefault(require("redux-saga"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);
  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);
    enumerableOnly && (symbols = symbols.filter(function (sym) {
      return Object.getOwnPropertyDescriptor(object, sym).enumerable;
    })), keys.push.apply(keys, symbols);
  }
  return keys;
}
function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = null != arguments[i] ? arguments[i] : {};
    i % 2 ? ownKeys(Object(source), !0).forEach(function (key) {
      _defineProperty(target, key, source[key]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) {
      Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
    });
  }
  return target;
}
function _defineProperty(obj, key, value) {
  key = _toPropertyKey(key);
  if (key in obj) {
    Object.defineProperty(obj, key, {
      value: value,
      enumerable: true,
      configurable: true,
      writable: true
    });
  } else {
    obj[key] = value;
  }
  return obj;
}
function _toPropertyKey(arg) {
  var key = _toPrimitive(arg, "string");
  return _typeof(key) === "symbol" ? key : String(key);
}
function _toPrimitive(input, hint) {
  if (_typeof(input) !== "object" || input === null) return input;
  var prim = input[Symbol.toPrimitive];
  if (prim !== undefined) {
    var res = prim.call(input, hint || "default");
    if (_typeof(res) !== "object") return res;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return (hint === "string" ? String : Number)(input);
}
function _typeof(obj) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  }, _typeof(obj);
}
function createAppStore(reducers, rootSaga) {
  var history = (0, _history.createBrowserHistory)();
  var sagaMiddleware = (0, _reduxSaga["default"])();
  var routeMiddleware = (0, _connectedReactRouter.routerMiddleware)(history);
  var middlewares = [_reduxThunk["default"], sagaMiddleware, routeMiddleware];
  var composeEnhancers = (typeof window === "undefined" ? "undefined" : _typeof(window)) === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
  }) : _redux.compose;
  var store = (0, _redux.createStore)((0, _redux.combineReducers)(_objectSpread(_objectSpread({}, reducers), {}, {
    router: (0, _connectedReactRouter.connectRouter)(history)
  })), composeEnhancers(_redux.applyMiddleware.apply(void 0, middlewares)));
  sagaMiddleware.run(rootSaga);
  return {
    store: store,
    history: history
  };
}

// OLD DELETE
// import { createStore, combineReducers, applyMiddleware, compose } from "redux";
// import { createBrowserHistory } from "history";
// import { connectRouter, routerMiddleware } from "connected-react-router";
// import thunk from "redux-thunk";
// import createSagaMiddleware from "redux-saga";
//
// import getReducers from "./reducers";
// import rootSaga from "../sagas/sagas";
//
//
// const history = createBrowserHistory();
// const sagaMiddleware = createSagaMiddleware();
// const routeMiddleware = routerMiddleware(history);
// const middlewares = [thunk, sagaMiddleware, routeMiddleware];
//
// const composeEnhancers =
//     typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
//         ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//             // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//         })
//         : compose;
//
// const store = createStore(
//     combineReducers({
//         ...getReducers(),
//         router: connectRouter(history)
//     }),
//     composeEnhancers(applyMiddleware(...middlewares))
// );
//
// sagaMiddleware.run(rootSaga);
//
// export {
//     store,
//     history,
// };