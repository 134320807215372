import actions from './actions';

const initialState = {
  dashboardIsLoading: true,
  Income: {},
  Expense: {},
  Goal: {},
  AboutYou: {},
  userProfile: {},
  isError: false,
  errorMessage: '',
  isProfileLoading: false,
  updatePasswordError: null,
  getuserBudgetWasCalled: false,
};

function updateElemArray(array, { id, monthlyAmount, yearlyAmount }) {
  const arrayCopy = array.slice();
  const updatedArray = arrayCopy.map(elem => {
    const updatedElem = elem;
    if (updatedElem.id === id) {
      updatedElem.monthlyAmount = monthlyAmount;
      updatedElem.yearlyAmount = yearlyAmount;
    }
    return updatedElem;
  });
  return updatedArray;
}

export default function dashboardReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.GET_USER_BUDGET: {
      return {
        ...state,
        getuserBudgetWasCalled: true,
      };
    }
    case actions.GET_USER_BUDGET_SUCCESS: {
      const { Income, Expense, Goal, AboutYou, userProfile } = payload;
      return {
        ...state,
        Income,
        Expense,
        Goal,
        AboutYou,
        userProfile,
        dashboardIsLoading: false,
        isError: false,
        errorMessage: '',
      };
    }
    case actions.GET_USER_BUDGET_ERROR: {
      return {
        ...state,
        dashboardIsLoading: false,
        isError: true,
        errorMessage: 'Something went wrong. Try again please.',
      };
    }
    case actions.UPDATE_USER_BUDGET: {
      return {
        ...state,
      };
    }
    case actions.UPDATE_USER_BUDGET_SUCCESS: {
      const { incomeExpense, typeCateg, updatedValue } = payload;
      return {
        ...state,
        [incomeExpense]: {
          ...state[incomeExpense],
          [typeCateg]: updateElemArray(state[incomeExpense][typeCateg], updatedValue),
        },
        dashboardIsLoading: false,
      };
    }
    case actions.UPDATE_USER_BUDGET_ERROR: {
      return {
        ...state,
        dashboardIsLoading: false,
        isError: true,
        errorMessage: 'Something went wrong. Try again please.',
      };
    }
    case actions.UPDATE_USER_PASSWORD: {
      return {
        ...state,
        isProfileLoading: true,
        updatePasswordError: null,
      };
    }
    case actions.UPDATE_USER_PASSWORD_SUCCESS: {
      return {
        ...state,
        isProfileLoading: false,
        updatePasswordError: true,
      };
    }
    case actions.UPDATE_USER_PASSWORD_ERROR: {
      return {
        ...state,
        isProfileLoading: false,
        updatePasswordError: false,
        isError: true,
        errorMessage: 'Something went wrong. Try again please.',
      };
    }
    case actions.RESET_USER_PROFILE: {
      return {
        ...state,
        isProfileLoading: false,
        updatePasswordError: null,
        isError: false,
        errorMessage: '',
      };
    }
    default:
      return state;
  }
}
