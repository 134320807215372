import service from 'dcom-app/dist/services';

const servicesApi = {
  createWorkSheet: payload =>
    service()
      .post('/budgetworksheet', payload)
      .then(response => response.data)
      .catch(error => error),
  getWorkSheetByUser: ({ userid }) =>
    service()
      .get(`/budgetworksheet?userId=${userid}`)
      .then(response => response.data)
      .catch(error => error),
  fetchAttrs: hostname =>
    service()
      .get(`/screen_attributes/?url=${hostname}`)
      .then(response => response.data.data)
      .catch(error => error),
  fetchCategories: () =>
    service()
      .get('/companyinfo')
      .then(response => response.data)
      .catch(error => error),
  postAttrValue: payload =>
    service()
      .post(`/user/info`, payload)
      .then(response => response.data)
      .catch(error => error),
  userBudget: ({ userid, worksheetid }) =>
    service()
      .get(`/user/info/${userid}/${worksheetid}`)
      .then(response => response.data)
      .catch(error => error),
  updateUserBudget: ({ id, payload }) =>
    service()
      .patch(`/incomeexpense/${id}`, payload)
      .then(response => response.data)
      .catch(error => error),
  updateUserPassword: ({ userid, payload }) =>
    service()
      .patch(`/user/${userid}`, payload)
      .then(response => response.data)
      .catch(error => error),
  getCompanyInfo: hostname =>
    service()
      .get(`/companyInfo?url=${hostname}`)
      .then(response => response.data),
  getBudgetSpendingCategories: ({ companyInfoId }) =>
    service()
      .get(`/companyInfo/${companyInfoId}/configurationSpending`)
      .then(response => response.data)
      .catch(error => error),
};

export default servicesApi;
