"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.MainTable = void 0;
var _antd = require("antd");
var _table = _interopRequireDefault(require("./table.styles"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var MainTable = (0, _table["default"])(_antd.Table);
exports.MainTable = MainTable;
var _default = _antd.Table;
exports["default"] = _default;