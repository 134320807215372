// process.env.REACT_APP_ENV;
const urls = ['budget-app.debt.com', 'budget-1-app.debt.com', 'kofeca-budget-app.debt.com'];

const checkForProdUrls = (hostname, prodUrls) => prodUrls.includes(hostname);

const ENV = checkForProdUrls(window.location.host, urls) ? 'production' : 'development';

console.log('stated like:', ENV);

const BASE_URL =
  ENV === 'production'
    ? 'https://mobile-backend-api.debt.com'
    : 'https://mobile-backend-api.debt.com';

const BASE_URL_DROPS_API = '';
// (ENV === 'production') ? 'https://drops-api.debt.com' : 'https://dev-drops-api.debt.com';

export { BASE_URL, BASE_URL_DROPS_API, ENV };
