const actions = {
  // START_APP
  START_APP: 'START_APP',
  // POST_ATTR_VALUE
  POST_ATTR_VALUE: 'POST_ATTR_VALUE',
  POST_ATTR_VALUE_SUCCESS: 'POST_ATTR_VALUE_SUCCESS',
  POST_ATTR_VALUE_ERROR: 'POST_ATTR_VALUE_ERROR',
  // GET WORKSHEET
  CREATE_WORKSHEET: 'CREATE_WORKSHEET',
  CREATE_WORKSHEET_SUCCESS: 'CREATE_WORKSHEET_SUCCESS',
  CREATE_WORKSHEET_ERROR: 'CREATE_WORKSHEET_ERROR',
  // GET WORKSHEET BY USER
  GET_WORKSHEET_BY_USER: 'GET_WORKSHEET_BY_USER',
  GET_WORKSHEET_SUCCESS_BY_USER: 'GET_WORKSHEET_SUCCESS_BY_USER',
  GET_WORKSHEET_ERROR_BY_USER: 'GET_WORKSHEET_ERROR_BY_USER',
  // FETCH DATA
  FETCH_ATTRS: 'FETCH_ATTRS',
  FETCH_ATTRS_SUCCESS: 'FETCH_ATTRS_SUCCESS',
  FETCH_ATTRS_ERROR: 'FETCH_ATTRS_ERROR',

  GET_COMPANY_INFO: 'GET_COMPANY_INFO',
  GET_COMPANY_INFO_SUCCESS: 'GET_COMPANY_INFO_SUCCESS',
  GET_COMPANY_INFO_ERROR: 'GET_COMPANY_INFO_ERROR',

  GET_USER_BUDGET: 'GET_USER_BUDGET',
  GET_USER_BUDGET_SUCCESS: 'GET_USER_BUDGET_SUCCESS',
  GET_USER_BUDGET_ERROR: 'GET_USER_BUDGET_ERROR',

  GET_BUDGET_SPENDING_CATEGORIES: 'GET_BUDGET_SPENDING_CATEGORIES',
  GET_BUDGET_SPENDING_CATEGORIES_SUCCESS: 'GET_BUDGET_SPENDING_CATEGORIES_SUCCESS',
  GET_BUDGET_SPENDING_CATEGORIES_ERROR: 'GET_BUDGET_SPENDING_CATEGORIES_ERROR',

  startApp: data => ({
    type: actions.START_APP,
    payload: data,
  }),

  postAttrValue: data => ({
    type: actions.POST_ATTR_VALUE,
    payload: data,
  }),
  postAttrValueSuccess: () => {
    return {
      type: actions.POST_ATTR_VALUE_SUCCESS,
    };
  },
  postAttrValueError: error => ({
    type: actions.POST_ATTR_VALUE_ERROR,
    payload: error,
  }),

  createWorkSheet: data => ({
    type: actions.CREATE_WORKSHEET,
    payload: data,
  }),
  createWorkSheetSuccess: data => ({
    type: actions.CREATE_WORKSHEET_SUCCESS,
    payload: data,
  }),
  createWorkSheetError: error => ({
    type: actions.CREATE_WORKSHEET_ERROR,
    payload: error,
  }),

  getWorkSheetByUser: data => ({
    type: actions.GET_WORKSHEET_BY_USER,
    payload: data,
  }),
  getWorkSheetSuccessByUser: data => ({
    type: actions.GET_WORKSHEET_SUCCESS_BY_USER,
    payload: data,
  }),
  getWorkSheetErrorByUser: error => ({
    type: actions.GET_WORKSHEET_ERROR_BY_USER,
    payload: error,
  }),

  fetchAttrs: () => ({
    type: actions.FETCH_ATTRS,
  }),
  fetchAttrsSuccess: data => ({
    type: actions.FETCH_ATTRS_SUCCESS,
    payload: data,
  }),
  fetchAttrsError: error => ({
    type: actions.FETCH_ATTRS_ERROR,
    payload: error,
  }),

  getCompanyInfo: () => ({
    type: actions.GET_COMPANY_INFO,
  }),
  getCompanyInfoSuccess: payload => ({
    type: actions.GET_COMPANY_INFO_SUCCESS,
    payload,
  }),
  getCompanyInfoError: error => ({
    type: actions.GET_COMPANY_INFO_ERROR,
    payload: error,
  }),

  getUserBudget: () => ({
    type: actions.GET_USER_BUDGET,
  }),
  getUserBudgetSuccess: payload => ({
    type: actions.GET_USER_BUDGET_SUCCESS,
    payload,
  }),
  getUserBudgetError: error => ({
    type: actions.GET_USER_BUDGET_ERROR,
    payload: error,
  }),

  getBudgetSpendingCategories: companyInfoId => ({
    type: actions.GET_BUDGET_SPENDING_CATEGORIES,
    payload: { companyInfoId },
  }),
  getBudgetSpendingCategoriesSuccess: payload => ({
    type: actions.GET_BUDGET_SPENDING_CATEGORIES_SUCCESS,
    payload,
  }),
  getBudgetSpendingCategoriesError: error => ({
    type: actions.GET_BUDGET_SPENDING_CATEGORIES_ERROR,
    payload: error,
  }),
};

export default actions;
