"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDefaultPath = getDefaultPath;
exports.getInitData = getInitData;
exports.setUrl = setUrl;
var _qs = _interopRequireDefault(require("qs"));
var _options = _interopRequireDefault(require("../containers/Sidebar/options"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function getInitData() {
  var initData = _qs["default"].parse(window.location.search.slice(1));
  if (initData.toggle) initData.toggle.free_shipping = initData.toggle.free_shipping === 'true' ? true : undefined;
  return initData;
}
function setUrl(searchState) {
  var search = searchState ? "".concat(window.location.pathname, "?").concat(_qs["default"].stringify(searchState)) : '';
  window.history.pushState(searchState, null, search);
}
function getDefaultPath() {
  var getParent = function getParent(lastRoute) {
    var parent = [];
    if (!lastRoute) return parent;
    parent.push(lastRoute);
    _options["default"].forEach(function (option) {
      if (option.children) {
        option.children.forEach(function (child) {
          if (child.key === lastRoute) {
            parent.push(option.key);
          }
        });
      }
    });
    return parent;
  };
  if (window && window.location.pathname) {
    var routes = window.location.pathname.split('/');
    if (routes.length > 1) {
      var lastRoute = routes[routes.length - 1];
      return getParent(lastRoute);
    }
  }
  return [];
}