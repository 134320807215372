/* eslint-disable no-unused-vars */
import { message as MessageManager } from 'antd';
import { push } from 'react-router-redux';
import { all, call, put, putResolve, select, take, takeEvery } from 'redux-saga/effects';
import { isEmptyObject } from '../../../helpers/utils';
import budgetAppActions from '../../../redux/budgetApp/actions';
import servicesApi from '../../../redux/budgetApp/services';
import actions from './actions';

const getUserId = state => state.Auth?.profile?.id || false;
const getWorksheetId = state => state.BudgetApp?.budgetWorkSheet?.id || null;
const getAttrsArentLoaded = state => state.BudgetApp?.allCategories.length === 0;

function* getUserBudgetSaga() {
  // GET USER ID
  const userid = yield select(getUserId);
  if (!userid) {
    yield call(() =>
      MessageManager.error('No previous data found.', 2).then(() =>
        MessageManager.info('Please log in or sign up.', 3),
      ),
    );
    yield put(push('/'));
    return;
  }
  try {
    let worksheetid = yield select(getWorksheetId);
    if (!worksheetid) {
      yield put(budgetAppActions.getWorkSheetByUser({ userid }));
      const worksheetByUser = yield take('GET_WORKSHEET_SUCCESS_BY_USER');
      worksheetid = worksheetByUser?.payload[0]?.id;
      if (!worksheetid) {
        MessageManager.destroy();

        const result = yield call(() =>
          MessageManager.error('No worksheet found for this user', 2),
        );

        let created;
        if (!result.isAxiosError) {
          created = yield call(() => MessageManager.info('Creating worksheet', 2));
        }

        if (!created.isAxiosError) {
          yield put(push('/budget-app'));
          return;
        }
      } // all worksheet for all months
    }
    const attrsArentLoaded = yield select(getAttrsArentLoaded);
    if (attrsArentLoaded) {
      yield putResolve(budgetAppActions.fetchAttrs());
      const waitForAllAttrs = yield take('FETCH_ATTRS_SUCCESS');
    }
    const { hostname } = window.location;
    const [budget, companyInfo] = yield all([
      call(servicesApi.userBudget, { userid, worksheetid }),
      call(servicesApi.getCompanyInfo, hostname),
    ]);
    if (!companyInfo.isAxiosError) {
      yield put(
        budgetAppActions.getCompanyInfoSuccess({
          companyInfo: companyInfo && companyInfo[0] ? companyInfo[0] : {},
        }),
      );
    }
    if (!budget.isAxiosError) {
      const { budgetData = [], globalUserData = {} } = budget;
      const userBudgetObj = budgetData[0]?.incomeExpenseId || {};
      if (isEmptyObject(userBudgetObj)) {
        yield put(push('/budget-app'));
        return;
      }
      const Income = budgetData[0]?.incomeExpenseId?.Income || {};
      const Expense = budgetData[0]?.incomeExpenseId?.Expense || {};
      const Goal = budgetData[0]?.incomeExpenseId?.Goal || {};
      const AboutYou = budgetData[0]?.incomeExpenseId?.AboutYou || {};
      yield put(
        actions.getUserBudgetSuccess({
          Income,
          Expense,
          Goal,
          AboutYou,
          userProfile: globalUserData,
        }),
      );
      return;
    }
    // error
    const message = companyInfo?.message || budget?.message;
    console.log('Error: paychecks:-->', message);
    yield put(actions.getUserBudgetError({ error: message }));

    if (budget.response && budget.response.status === 401) {
      MessageManager.destroy();
      MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    MessageManager.destroy();
    MessageManager.error('We had an error. Please reload the page.');
    yield put(actions.getUserBudgetError(error));
    yield put(budgetAppActions.getCompanyInfoError(error));
  }
}

function* updateUserBudgetSaga({ payload }) {
  if (!isNaN(payload.payload.yearlyAmount)) {
    MessageManager.loading({
      key: 'waiting-update',
      content: 'Updating value, please wait.',
      duration: 0,
    });

    try {
      const resp = yield call(servicesApi.updateUserBudget, payload);
      if (!resp.isAxiosError) {
        const { id, monthlyAmount, yearlyAmount, appAttributeId } = resp;

        const { type: typeCateg, incomeExpense } = appAttributeId; // type:Goals incomeExpense:Goal
        yield put(
          actions.updateUserBudgetSuccess({
            incomeExpense, // Goal
            typeCateg, // Goals
            updatedValue: { id, monthlyAmount, yearlyAmount },
          }),
        );
        MessageManager.destroy('waiting-update');
        MessageManager.success({ content: 'Successfully updated', duration: 2 });

        return;
      }

      // error
      if (resp.response && resp.response.status === 401) {
        MessageManager.destroy('waiting-update');
        MessageManager.error('Session expired please login again');
      }
      const { message } = resp;
      console.log('Error: updateUserBudget:-->', message);
      yield put(actions.updateUserBudgetError({ error: message }));
      yield put(actions.getUserBudget());
      MessageManager.destroy('waiting-update');
      MessageManager.error('Error occurred while updating. Please try again');
    } catch (error) {
      console.log(error);
      yield put(actions.updateUserBudgetError(error));
      yield put(actions.getUserBudget());
      MessageManager.destroy('waiting-update');
      MessageManager.error('Error occurred while updating. Please try again');
    }
  }
}

function* updateNewAttrSaga({ payload }) {
  if (!isNaN(payload.yearlyAmount)) {
    MessageManager.loading({
      key: 'waiting-updated',
      content: 'Updating value, please wait.',
      duration: 0,
    });
    try {
      yield put(budgetAppActions.postAttrValue({ arrayAttrValues: [payload] }));
      const waitFor = yield take('POST_ATTR_VALUE_SUCCESS');
      yield put(actions.getUserBudget());
      MessageManager.destroy('waiting-updated');
      MessageManager.success({ content: 'Successfully updated', duration: 2 });
    } catch (error) {
      MessageManager.destroy('waiting-updated');
      MessageManager.error("An error happened while trying to update new attribute's value");
    }
  }
}

function* updateUserPasswordSaga({ payload }) {
  try {
    const userid = yield select(getUserId);
    const resp = yield call(servicesApi.updateUserPassword, { userid, payload });
    if (!resp.isAxiosError) {
      yield put(actions.updateUserPasswordSuccess());
      return;
    }
    // error
    const { message } = resp;
    console.log('Error: paychecks:-->', message);
    yield put(actions.updateUserPasswordError({ error: message }));
    if (resp.response && resp.response.status === 401) {
      // MessageManager.error('Session expired please login again');
    }
  } catch (error) {
    console.log(error);
    yield put(actions.updateUserPasswordError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_USER_BUDGET, getUserBudgetSaga),
    takeEvery(actions.UPDATE_USER_BUDGET, updateUserBudgetSaga),
    takeEvery(actions.UPDATE_USER_PASSWORD, updateUserPasswordSaga),
    takeEvery(actions.UPDATE_NEW_ATTR, updateNewAttrSaga),
  ]);
}
