"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.borderRadius = borderRadius;
exports.boxShadow = boxShadow;
exports.transition = transition;
// import { css } from 'styled-components';

// Transition
function transition() {
  var timing = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0.3;
  return "\n      -webkit-transition: all ".concat(timing, "s cubic-bezier(0.215, 0.61, 0.355, 1);\n      -moz-transition: all ").concat(timing, "s cubic-bezier(0.215, 0.61, 0.355, 1);\n      -ms-transition: all ").concat(timing, "s cubic-bezier(0.215, 0.61, 0.355, 1);\n      -o-transition: all ").concat(timing, "s cubic-bezier(0.215, 0.61, 0.355, 1);\n      transition: all ").concat(timing, "s cubic-bezier(0.215, 0.61, 0.355, 1);\n  ");
}

// Border Radius
function borderRadius() {
  var radius = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return "\n      -webkit-border-radius: ".concat(radius, ";\n      -moz-border-radius: ").concat(radius, ";\n      -ms-transition: ").concat(radius, ";\n      -o-border-radius: ").concat(radius, ";\n      border-radius: ").concat(radius, ";\n  ");
}

// Box Shadow
function boxShadow() {
  var shadow = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "none";
  return "\n      -webkit-box-shadow: ".concat(shadow, ";\n      -moz-box-shadow: ").concat(shadow, ";\n      box-shadow: ").concat(shadow, ";\n  ");
}