export const roundTwoPositions = num => {
  return Math.round((num + Number.EPSILON) * 100) / 100;
};

const setCursorPosition = inputRef => {
  if (inputRef.setSelectionRange) {
    inputRef.setSelectionRange(1, 1);
  }
  inputRef.focus();
};

export const currentMonthYear = (dt, next = null) => {
  const mlist = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const month = dt.getMonth();
  const year = dt.getFullYear();

  if (next) {
    const isDecember = month === 11;
    return `${isDecember ? 'January' : mlist[dt.getMonth() + 1]} ${isDecember ? year + 1 : year}`;
  }

  return `${mlist[dt.getMonth()]} ${dt.getFullYear()}`;
};

export const isEmptyObject = obj => Object.keys(obj).length === 0;

export const calculateDashboardTotals = entries => {
  let total = 0;
  if (entries && Array.isArray(entries)) {
    total = entries.reduce((accum, currentEntry) => {
      accum += Number(currentEntry.monthlyAmount);
      return roundTwoPositions(accum);
    }, 0);
    return roundTwoPositions(total);
  }
  return roundTwoPositions(total);
};

export default setCursorPosition;
