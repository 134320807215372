import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import asyncComponent from 'dcom-app/dist/helpers/AsyncFunc';
import MainApp from 'dcom-app/dist/containers/App/App';

const modulesRoutes = [
  {
    path: '',
    component: asyncComponent(() => import('./containers/homepage')),
    roles: [],
  },
  {
    path: 'sign-in',
    component: asyncComponent(() => import('./containers/sign-in')),
    roles: [],
  },
  {
    path: 'sign-up',
    component: asyncComponent(() => import('./containers/sign-up')),
    roles: [],
  },
  {
    path: 'forgot-password',
    component: asyncComponent(() => import('./containers/forgot-password')),
    roles: [],
  },
  {
    path: 'reset-password',
    component: asyncComponent(() => import('./containers/reset-password')),
    roles: [],
  },
  {
    path: 'budget-app',
    exact: false,
    component: asyncComponent(() => import('./containers/dynamic-app')),
    roles: [],
  },
  {
    path: 'dashboard',
    component: asyncComponent(() => import('./containers/dashboard')),
    roles: [],
  },
  {
    path: 'profile',
    component: asyncComponent(() => import('./containers/profile')),
    roles: [],
  },
  {
    path: 'spending-insights',
    component: asyncComponent(() => import('./containers/spending-insights')),
    roles: [],
  },
  {
    path: 'videos',
    component: asyncComponent(() => import('./containers/videos')),
    roles: [],
  },
  {
    path: 'goals',
    component: asyncComponent(() => import('./containers/goals')),
    roles: [],
  },
  {
    path: 'print',
    component: asyncComponent(() => import('./containers/print')),
    roles: [],
  },
];

const RestrictedRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isLoggedIn ? (
        <Component {...props} {...rest} />
      ) : (
        <Redirect to={{ pathname: '/', state: { from: props.location } }} />
      )
    }
  />
);

export const getPublicRouters = roles => {
  return modulesRoutes.filter(v => v.roles.length === 0);
};

export const getPrivateRouters = roles => {
  return modulesRoutes.filter(
    r => r.roles.length > 0 && r.roles.some(value => roles.includes(value)),
  );
};

export const getAppRouters = (roles, isLoggedIn) => {
  const publicRouters = getPublicRouters(roles);
  const privateRouters = getPrivateRouters(roles);

  return (
    <Switch>
      {publicRouters.map(p => (
        <Route key={p.path} exact={p.exact !== false} path={`/${p.path}`} component={p.component} />
      ))}
      <RestrictedRoute
        path="/budget"
        component={MainApp}
        isLoggedIn={isLoggedIn}
        privateRouters={privateRouters}
        customTopbar={null}
        customSidebar={null}
        customFooter={null}
      />
      <Route component={asyncComponent(() => import('./containers/not-found'))} />
    </Switch>
  );
};
