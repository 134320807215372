"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _customizations = _interopRequireDefault(require("./customizations"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var currentCustomization = function currentCustomization(currentDomain) {
  var configData = _customizations["default"].find(function (custom) {
    return custom.appUrl === currentDomain;
  });
  if (!configData) configData = _customizations["default"][0];
  if (configData.favicon) updateAppFavicon(configData.favicon);
  if (configData.appTitle) updateAppTitle(configData.appTitle);
  if (configData.appDescription) updateAppDescription(configData.appDescription);
  return configData;
};
var updateAppFavicon = function updateAppFavicon(favicon) {
  var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = favicon || 'favicons/faviconlarge.png';
  document.getElementsByTagName('head')[0].appendChild(link);
};
var updateAppTitle = function updateAppTitle(title) {
  document.title = title || 'Budget App';
};
var updateAppDescription = function updateAppDescription(description) {
  return false;
};
var _default = currentCustomization;
exports["default"] = _default;