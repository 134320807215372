import _ from 'lodash';

const getGroups = dataSource => {
  const groups = [];
  dataSource.map(elem => !groups.includes(elem.attribute.type) && groups.push(elem.attribute.type));
  return groups;
};

const groupByIncomeExpense = dataSource =>
  _.groupBy(dataSource, obj => obj.attribute.incomeExpense);

export { getGroups, groupByIncomeExpense };
