const actions = {
  GET_USER_BUDGET: 'GET_USER_BUDGET',
  GET_USER_BUDGET_SUCCESS: 'GET_USER_BUDGET_SUCCESS',
  GET_USER_BUDGET_ERROR: 'GET_USER_BUDGET_ERROR',
  UPDATE_NEW_ATTR: 'UPDATE_NEW_ATTR',
  UPDATE_NEW_ATTR_SUCCESS: 'UPDATE_NEW_ATTR_SUCCESS',
  UPDATE_NEW_ATTR_ERROR: 'UPDATE_NEW_ATTR_ERROR',
  UPDATE_USER_BUDGET: 'UPDATE_USER_BUDGET',
  UPDATE_USER_BUDGET_SUCCESS: 'UPDATE_USER_BUDGET_SUCCESS',
  UPDATE_USER_BUDGET_ERROR: 'UPDATE_USER_BUDGET_ERROR',
  UPDATE_USER_PASSWORD: 'UPDATE_USER_PASSWORD',
  UPDATE_USER_PASSWORD_SUCCESS: 'UPDATE_USER_PASSWORD_SUCCESS',
  UPDATE_USER_PASSWORD_ERROR: 'UPDATE_USER_PASSWORD_ERROR',
  RESET_USER_PROFILE: 'RESET_USER_PROFILE',
  getUserBudget: () => ({
    type: actions.GET_USER_BUDGET,
  }),
  getUserBudgetSuccess: payload => ({
    type: actions.GET_USER_BUDGET_SUCCESS,
    payload,
  }),
  getUserBudgetError: error => ({
    type: actions.GET_USER_BUDGET_ERROR,
    payload: error,
  }),

  updateNewAttr: payload => ({
    type: actions.UPDATE_NEW_ATTR,
    payload,
  }),

  updateUserBudget: payload => ({
    type: actions.UPDATE_USER_BUDGET,
    payload,
  }),
  updateUserBudgetSuccess: payload => ({
    type: actions.UPDATE_USER_BUDGET_SUCCESS,
    payload,
  }),
  updateUserBudgetError: error => ({
    type: actions.UPDATE_USER_BUDGET_ERROR,
    payload: error,
  }),

  updateUserPassword: payload => ({
    type: actions.UPDATE_USER_PASSWORD,
    payload,
  }),
  updateUserPasswordSuccess: () => ({
    type: actions.UPDATE_USER_PASSWORD_SUCCESS,
  }),
  updateUserPasswordError: error => ({
    type: actions.UPDATE_USER_PASSWORD_ERROR,
    payload: error,
  }),

  resetUserProfile: () => ({ type: actions.RESET_USER_PROFILE }),
};

export default actions;
