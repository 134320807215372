"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TitleWrapper = exports.TableWrapper = exports.StatusTag = exports.Label = exports.Form = exports.Fieldset = exports.ComponentTitle = exports.ButtonHolders = exports.ActionWrapper = exports.ActionBtn = void 0;
var _styledComponents = _interopRequireDefault(require("styled-components"));
var _styledTheme = require("styled-theme");
var _button = _interopRequireDefault(require("dcom-app/dist/components/uielements/button"));
var _antTable = _interopRequireDefault(require("dcom-app/dist/containers/Tables/antTables/antTable.style"));
var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10;
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
function _taggedTemplateLiteral(strings, raw) {
  if (!raw) {
    raw = strings.slice(0);
  }
  return Object.freeze(Object.defineProperties(strings, {
    raw: {
      value: Object.freeze(raw)
    }
  }));
}
var TableWrapper = (0, _styledComponents["default"])(_antTable["default"])(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  .ant-table-bordered .ant-table-thead > tr > th,\n  .ant-table-bordered .ant-table-tbody > tr > td {\n    white-space: normal;\n    &.noWrapCell {\n      white-space: nowrap;\n    }\n\n    @media only screen and (max-width: 920px) {\n      white-space: nowrap;\n    }\n  }\n"])));
exports.TableWrapper = TableWrapper;
var StatusTag = _styledComponents["default"].span(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  padding: 0 5px;\n  height: 20px;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  border-radius: 2px;\n  background-color: ", ";\n  font-size: 12px;\n  color: #ffffff;\n  text-transform: capitalize;\n\n  &.draft {\n    background-color: ", ";\n  }\n\n  &.publish {\n    background-color: ", ";\n  }\n"])), (0, _styledTheme.palette)('primary', 0), (0, _styledTheme.palette)('warning', 0), (0, _styledTheme.palette)('success', 0));
exports.StatusTag = StatusTag;
var TitleWrapper = _styledComponents["default"].div(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  display: flex;\n  justify-content: space-between;\n  margin-bottom: 20px;\n  flex-wrap: wrap;\n  align-items: center;\n"])));
exports.TitleWrapper = TitleWrapper;
var ButtonHolders = _styledComponents["default"].div(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral([""])));
exports.ButtonHolders = ButtonHolders;
var ComponentTitle = _styledComponents["default"].h3(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  font-size: 16px;\n  font-weight: 500;\n  color: ", ";\n  margin: 5px 0;\n"])), (0, _styledTheme.palette)('text', 0));
exports.ComponentTitle = ComponentTitle;
var ActionBtn = (0, _styledComponents["default"])(_button["default"])(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  && {\n    padding: 0 12px;\n    margin-right: 15px;\n\n    &:last-child {\n      margin-right: 0;\n    }\n    i {\n      font-size: 17px;\n      color: ", ";\n    }\n\n    &:hover {\n      i {\n        color: inherit;\n      }\n    }\n  }\n"])), (0, _styledTheme.palette)('text', 1));
exports.ActionBtn = ActionBtn;
var Fieldset = _styledComponents["default"].div(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  margin-bottom: 20px;\n\n  &:last-child {\n    margin-bottom: 0;\n  }\n"])));
exports.Fieldset = Fieldset;
var Label = _styledComponents["default"].label(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  font-size: 13px;\n  color: ", ";\n  line-height: 1.5;\n  font-weight: 500;\n  padding: 0;\n  margin: 0 0 8px;\n"])), (0, _styledTheme.palette)('text', 1));
exports.Label = Label;
var ActionWrapper = _styledComponents["default"].div(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  display: flex;\n  align-content: center;\n\n  a {\n    margin-right: 12px;\n    &:last-child {\n      margin-right: 0;\n    }\n\n    i {\n      font-size: 18px;\n      color: ", ";\n\n      &:hover {\n        color: ", ";\n      }\n    }\n\n    &.deleteBtn {\n      i {\n        color: ", ";\n\n        &:hover {\n          color: ", ";\n        }\n      }\n    }\n  }\n"])), (0, _styledTheme.palette)('primary', 0), (0, _styledTheme.palette)('primary', 4), (0, _styledTheme.palette)('error', 0), (0, _styledTheme.palette)('error', 2));
exports.ActionWrapper = ActionWrapper;
var Form = _styledComponents["default"].div(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral([""])));
exports.Form = Form;