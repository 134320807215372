"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.themeConfig = exports.siteConfig = exports.jwtConfig = exports.firebaseConfig = exports.accountKitConfig = exports.Auth0Config = void 0;
// const apiUrl = (process.env.NODE_ENV === "development") ? "http://localhost:1337": "https://drops-dashboard-bk.debt.com";

var siteConfig = {
  siteName: "DCOM DROP APP",
  lightLogo: "https://styles.debt.com/images/logo-full-color-white-text.png",
  darkLogo: "https://styles.debt.com/images/logo-full-color-no-tag.png",
  miniLogo: "https://styles.debt.com/images/tree.png",
  siteIcon16x16: "",
  siteIcon32x32: "",
  appleTouchIcon: "",
  footerText: "Debt.com ©2022 Created by Debt.com"
};
exports.siteConfig = siteConfig;
var themeConfig = {
  topbar: "themedefault",
  sidebar: "themedefault",
  layout: "themedefault",
  theme: "themedefault"
};
exports.themeConfig = themeConfig;
var Auth0Config = {
  domain: "",
  clientID: "",
  allowedConnections: ["Username-Password-Authentication"],
  rememberLastLogin: true,
  language: "en",
  closable: true,
  options: {
    auth: {
      autoParseHash: true,
      redirect: true,
      redirectUrl: "http://localhost:3000/auth0loginCallback"
    },
    languageDictionary: {
      title: "Isomorphic",
      emailInputPlaceholder: "demo@gmail.com",
      passwordInputPlaceholder: "demodemo"
    },
    theme: {
      labeledSubmitButton: true,
      logo: "",
      primaryColor: "#E14615",
      authButtons: {
        connectionName: {
          displayName: "Log In",
          primaryColor: "#b7b7b7",
          foregroundColor: "#000000"
        }
      }
    }
  }
};
exports.Auth0Config = Auth0Config;
var firebaseConfig = {
  apiKey: "",
  authDomain: "",
  databaseURL: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: ""
};
exports.firebaseConfig = firebaseConfig;
var accountKitConfig = {
  appId: "",
  state: "csrf",
  version: "v1.0",
  fbAppEventsEnabled: true,
  redirect: "",
  debug: process.env.NODE_ENV !== "production"
};
exports.accountKitConfig = accountKitConfig;
var jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey'
};
exports.jwtConfig = jwtConfig;