"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
exports.getCurrentTheme = getCurrentTheme;
var _settings = require("../../settings");
var changeThemes = {
  id: "changeThemes",
  label: "themeSwitcher",
  defaultTheme: _settings.themeConfig.theme,
  options: [{
    themeName: "themedefault",
    buttonColor: "#ffffff",
    textColor: "#323332"
  }, {
    themeName: "theme2",
    buttonColor: "#ffffff",
    textColor: "#323332"
  }]
};
var topbarTheme = {
  id: "topbarTheme",
  label: "themeSwitcher.Topbar",
  defaultTheme: _settings.themeConfig.topbar,
  options: [{
    themeName: "themedefault",
    buttonColor: "#ffffff",
    textColor: "#323332"
  }, {
    themeName: "theme1",
    buttonColor: "#e0364c",
    backgroundColor: "#e0364c",
    textColor: "#ffffff"
  }, {
    themeName: "theme2",
    buttonColor: "#6534ff",
    backgroundColor: "#6534ff",
    textColor: "#ffffff"
  }, {
    themeName: "theme3",
    buttonColor: "#4482FF",
    backgroundColor: "#4482FF",
    textColor: "#ffffff"
  }, {
    themeName: "theme4",
    buttonColor: "#422e62",
    backgroundColor: "#422e62",
    textColor: "#ffffff"
  }, {
    themeName: "theme5",
    buttonColor: "#22144c",
    backgroundColor: "#22144c",
    textColor: "#ffffff"
  }, {
    themeName: "theme6",
    buttonColor: "#4670a2",
    backgroundColor: "#4670a2",
    textColor: "#ffffff"
  }, {
    themeName: "theme7",
    buttonColor: "#494982",
    backgroundColor: "#494982",
    textColor: "#ffffff"
  }]
};
var sidebarTheme = {
  id: "sidebarTheme",
  label: "themeSwitcher.Sidebar",
  defaultTheme: _settings.themeConfig.sidebar,
  options: [{
    themeName: "themedefault",
    buttonColor: "#323332",
    backgroundColor: undefined,
    textColor: "#788195"
  }, {
    themeName: "theme1",
    buttonColor: "#e0364c",
    backgroundColor: "#e0364c",
    textColor: "#ffffff"
  }, {
    themeName: "theme2",
    buttonColor: "#6534ff",
    backgroundColor: "#6534ff",
    textColor: "#ffffff"
  }, {
    themeName: "theme3",
    buttonColor: "#4482FF",
    backgroundColor: "#4482FF",
    textColor: "#ffffff"
  }, {
    themeName: "theme4",
    buttonColor: "#422e62",
    backgroundColor: "#422e62",
    textColor: "#ffffff"
  }, {
    themeName: "theme5",
    buttonColor: "#22144c",
    backgroundColor: "#22144c",
    textColor: "#ffffff"
  }, {
    themeName: "theme6",
    buttonColor: "#4670a2",
    backgroundColor: "#4670a2",
    textColor: "#ffffff"
  }, {
    themeName: "theme7",
    buttonColor: "#494982",
    backgroundColor: "#494982",
    textColor: "#ffffff"
  }]
};
var layoutTheme = {
  id: "layoutTheme",
  label: "themeSwitcher.Background",
  defaultTheme: _settings.themeConfig.layout,
  options: [{
    themeName: "themedefault",
    buttonColor: "#ffffff",
    backgroundColor: "#F1F3F6",
    textColor: undefined
  }, {
    themeName: "theme1",
    buttonColor: "#ffffff",
    backgroundColor: "#ffffff",
    textColor: "#323232"
  }, {
    themeName: "theme2",
    buttonColor: "#F9F9F9",
    backgroundColor: "#F9F9F9",
    textColor: "#ffffff"
  }, {
    themeName: "theme3",
    buttonColor: "#ebebeb",
    backgroundColor: "#ebebeb",
    textColor: "#ffffff"
  }]
};
var customizedThemes = {
  changeThemes: changeThemes,
  topbarTheme: topbarTheme,
  sidebarTheme: sidebarTheme,
  layoutTheme: layoutTheme
};
function getCurrentTheme(attribute, selectedThemename) {
  var selecetedTheme = {};
  customizedThemes[attribute].options.forEach(function (theme) {
    if (theme.themeName === selectedThemename) {
      selecetedTheme = theme;
    }
  });
  return selecetedTheme;
}
var _default = customizedThemes;
exports["default"] = _default;