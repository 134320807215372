import actions from './actions';

const initState = {
  isLoading: true,
  budgetWorkSheet: {},
  allCategories: [],
  dynamicRoutes: [],
  attrsGroupByCateg: {},
  companyInfo: {},
  budgetCategories: [],
  budgetConfigurationSpending: [],
  isError: false,
  errorMessage: '',
};

export default function reducer(state = initState, action) {
  const { type, payload } = action;

  switch (type) {
    case actions.CREATE_WORKSHEET: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.CREATE_WORKSHEET_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        budgetWorkSheet: payload,
      };
    }
    case actions.CREATE_WORKSHEET_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Budget Worksheet request failed. Please come back later',
      };
    }
    case actions.GET_WORKSHEET_BY_USER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.GET_WORKSHEET_SUCCESS_BY_USER: {
      return {
        ...state,
        isLoading: false,
        isError: false,
        budgetWorkSheet: payload,
      };
    }
    case actions.GET_WORKSHEET_ERROR_BY_USER: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Budget Worksheet request failed. Please come back later',
      };
    }
    case actions.FETCH_ATTRS: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case actions.FETCH_ATTRS_SUCCESS: {
      const {
        allCategories,
        incomeAttrs,
        incomeCategories,
        goalAttrs,
        goalCategories,
        aboutYouAttrs,
        aboutYouCategories,
        expenseAttrs,
        expenseCategories,
        dynamicRoutes,
        attrsGroupByCateg,
      } = payload;
      return {
        ...state,
        allCategories,
        incomeAttrs,
        incomeCategories,
        goalAttrs,
        goalCategories,
        aboutYouAttrs,
        aboutYouCategories,
        expenseAttrs,
        expenseCategories,
        dynamicRoutes,
        attrsGroupByCateg,
        isLoading: false,
        isError: false,
      };
    }
    case actions.FETCH_ATTRS_ERROR: {
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Attrs request failed. Please come back later',
      };
    }
    // POST_INCOME
    case actions.POST_ATTR_VALUE: {
      return {
        ...state,
      };
    }
    case actions.POST_ATTR_VALUE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isError: false,
        errorMessage: '',
      };
    case actions.POST_ATTR_VALUE_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'There was a problem posting Expenses',
      };
    // COMPANY_INFO
    case actions.GET_COMPANY_INFO:
      return {
        ...state,
      };
    case actions.GET_COMPANY_INFO_SUCCESS:
      return {
        ...state,
        companyInfo: payload.companyInfo || {},
      };
    case actions.GET_COMPANY_INFO_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Company Info request failed.',
      };
    // BUDGET_SPENDING_CATEGORIES
    case actions.GET_BUDGET_SPENDING_CATEGORIES:
      return {
        ...state,
      };
    case actions.GET_BUDGET_SPENDING_CATEGORIES_SUCCESS:
      return {
        ...state,
        budgetCategories: payload.categories || [],
        budgetConfigurationSpending: payload.configurationSpending || [],
      };
    case actions.GET_BUDGET_SPENDING_CATEGORIES_ERROR:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: 'Budget Categories request failed.',
      };
    default:
      return state;
  }
}
