"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.Textarea = exports.InputSearch = exports.InputGroup = void 0;
var _antd = require("antd");
var _input = require("./input.style");
var _withDirection = _interopRequireDefault(require("../../settings/withDirection"));
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}
var Search = _antd.Input.Search,
  TextArea = _antd.Input.TextArea,
  Group = _antd.Input.Group;
var WDStyledInput = (0, _input.InputWrapper)(_antd.Input);
var StyledInput = (0, _withDirection["default"])(WDStyledInput);
var WDInputGroup = (0, _input.InputGroupWrapper)(Group);
var InputGroup = (0, _withDirection["default"])(WDInputGroup);
exports.InputGroup = InputGroup;
var WDInputSearch = (0, _input.InputSearchWrapper)(Search);
var InputSearch = (0, _withDirection["default"])(WDInputSearch);
exports.InputSearch = InputSearch;
var WDTextarea = (0, _input.TextAreaWrapper)(TextArea);
var Textarea = (0, _withDirection["default"])(WDTextarea);
exports.Textarea = Textarea;
var _default = StyledInput;
exports["default"] = _default;