"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var MODULE = 'USER_PROFILE';
var actions = {
  UPDATE: "UPDATE_SELECTED_".concat(MODULE),
  UPDATE_SIGNATURE: "UPDATE_SIGNATURE_".concat(MODULE),
  LOAD_SIGNATURE: "LOAD_SIGNATURE_".concat(MODULE),
  LOAD_SIGNATURE_SUCCESS: "LOAD_SIGNATURE_SUCCESS_".concat(MODULE),
  UPDATE_SIGNATURE_MODEL: "UPDATE_SIGNATURE_MODEL_".concat(MODULE),
  updateProfile: function updateProfile(data) {
    return {
      type: actions.UPDATE,
      payload: {
        data: data
      }
    };
  },
  updateSignature: function updateSignature(data) {
    return {
      type: actions.UPDATE_SIGNATURE,
      payload: {
        data: data
      }
    };
  },
  onLoadSignature: function onLoadSignature(id) {
    return {
      type: actions.LOAD_SIGNATURE,
      payload: {
        data: id
      }
    };
  },
  onLoadSignatureSuccess: function onLoadSignatureSuccess(data) {
    return {
      type: actions.LOAD_SIGNATURE_SUCCESS,
      payload: {
        data: data
      }
    };
  },
  onUpdateSignatureModel: function onUpdateSignatureModel(value) {
    return {
      type: actions.UPDATE_SIGNATURE_MODEL,
      payload: {
        data: value
      }
    };
  }
};
var _default = actions;
exports["default"] = _default;