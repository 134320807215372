"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var options = [
  // {
  //   key: "configuration",
  //   label: "System",
  //   leftIcon: "ion-gear-b"
  // }
];
var _default = options;
exports["default"] = _default;